import { ContentCards } from '@braze/web-sdk';
import { useStoredUser } from 'app/hooks/store/use-stored-user.hook';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import config from './config';
import { CustomContentCardData } from './types';
import { useInterval } from '@app/hooks/use-interval.hook';

export interface BrazeContextType {
  triggerLogCustomEvent?: (eventName: string, eventProperties?: object) => void;
  handleCardDismissal?: (card: CustomContentCardData) => void;
  handleCardClick?: (card: CustomContentCardData) => void;
  handleCardImpression?: (card: CustomContentCardData) => void;
  handleLogContentCardImpressions?: (cards: CustomContentCardData[]) => void;
  requestFeedRefresh?: () => void;
  contentCards: CustomContentCardData[];
  isBrazeInitialized: boolean
}

export const BrazeContext = React.createContext<BrazeContextType>({
  triggerLogCustomEvent: (eventName: string, eventProperties?: object) => null,
  handleCardDismissal: (card: CustomContentCardData) => {},
  handleCardClick: (card: CustomContentCardData) => {},
  handleCardImpression: (card: CustomContentCardData) => {},
  handleLogContentCardImpressions: (cards: CustomContentCardData[]) => {},
  requestFeedRefresh: () => {},
  contentCards: [],
  isBrazeInitialized: false
});

export const BrazeSdkInitializer = ({ children }) => {
  const user = useStoredUser();
  const ldFlags = useFlags();
  const [contentCards, setContentCards] = useState<CustomContentCardData[]>([]);
  const [isBrazeInitialized, setIsBrazeInitialized] = useState<boolean>(false)

  const initializationValues = useMemo(() => {
    const hostname = window?.location?.hostname;
    const isLocal = hostname === 'localhost';
    const isDev = hostname?.startsWith('dev');
    const isInt = hostname?.startsWith('int');
    const isProd = hostname?.startsWith('www.navispherecarrier') || hostname?.startsWith('navispherecarrier');
    let shouldInitialize = ldFlags.initializeBraze && process.env.NODE_ENV != 'test';

    return {
      isLocal,
      isDev,
      isInt,
      isProd,
      shouldInitialize,
    };
  }, [ldFlags.initializeBraze]);

  // This function will be called to update state when ever there is an update to the content cards.
  const contentCardsUpdatesSubscriber = useCallback(
    (e: ContentCards) => setContentCards(e.cards as CustomContentCardData[]),
    []
  );

  useEffect(() => {
    const { shouldInitialize, isProd, isInt, isDev, isLocal } = initializationValues;

    if (shouldInitialize && user?.userId) {
      import('@braze/web-sdk').then(
        ({
          initialize,
          changeUser,
          subscribeToContentCardsUpdates,
          subscribeToInAppMessage,
          requestContentCardsRefresh,
          showInAppMessage,
          openSession,
          wipeData,
          getUser
        }) => {
          const { keys, endPoint } = config;
          let key = '';

          if (isLocal || isDev) {
            key = keys.dev;
          } else if (isInt) {
            key = keys.int;
          } else if (isProd) {
            key = keys.prod;
          }

          const isInitialized = initialize(key, {
            baseUrl: endPoint,
            enableLogging: isLocal,
            allowUserSuppliedJavascript: true,
          });

          setIsBrazeInitialized(isInitialized)

          if (isInitialized) {
            wipeData()

            const externalId = user.contactId ? `MDM${user.contactId}` : `NC${user.userId}`; // Use MDM contact Id else Nav Carrier userId.
            changeUser(externalId);

            getUser().addAlias(`${user.userId}`, 'nav_carrier_user_id');

            if (ldFlags.brazeContentCards) {
              subscribeToContentCardsUpdates(contentCardsUpdatesSubscriber);
              requestContentCardsRefresh();
            }

            if (ldFlags.brazeInAppMessages) {
              subscribeToInAppMessage(message => showInAppMessage(message));
            }

            openSession();
          }
        }
      );
    }    
  }, [
    initializationValues.shouldInitialize,
    ldFlags.logBrazeLaunchDarklyFlagsEvent && ldFlags,
    user?.userId,
    user?.contactId,
  ]);

  // Send custom event to Braze server
  const triggerLogCustomEvent = useCallback((eventName: string, eventProperties?: object) => {
    import('@braze/web-sdk').then(({ logCustomEvent }) => logCustomEvent(eventName, eventProperties));
  }, []);

  // Manually log custom card dismissal
  const handleCardDismissal = useCallback((card: CustomContentCardData) => {
    import('@braze/web-sdk').then(({ logCardDismissal }) => logCardDismissal(card));
  }, []);

  // Manually log custom card click
  const handleCardClick = useCallback((card: CustomContentCardData) => {
    import('@braze/web-sdk').then(({ logContentCardClick }) => logContentCardClick(card));
  }, []);

  // Manually log custom card Impression
  const handleCardImpression = useCallback((card: CustomContentCardData) => {
    import('@braze/web-sdk').then(({ logContentCardImpressions }) => logContentCardImpressions([card]));
  }, []);

  const handleLogContentCardImpressions = useCallback((cards: CustomContentCardData[]) => {
    import('@braze/web-sdk').then(({ logContentCardImpressions }) => logContentCardImpressions(cards));
  }, []);

  // Manually request feed refresh
  const requestFeedRefresh = useCallback(() => {
    import('@braze/web-sdk').then(({ requestContentCardsRefresh }) => requestContentCardsRefresh());
  }, []);

  // Run Every 3 minutes
  useInterval(() => {
    if(isBrazeInitialized){
      requestFeedRefresh()
    }
  }, 3*60*1000);


  const contextValues = {
    triggerLogCustomEvent,
    handleCardDismissal,
    handleCardImpression,
    handleCardClick,
    handleLogContentCardImpressions,
    requestFeedRefresh,
    contentCards,
    isBrazeInitialized
  };

  return <BrazeContext.Provider value={contextValues}>{children}</BrazeContext.Provider>;
};

export function useBrazeContext() {
  return React.useContext(BrazeContext);
}
